<template>
  <div class="stats">
    <StatsText />
    <PlotlyChart />
  </div>
</template>

<script>
// @ is an alias to /src
import PlotlyChart from "@/components/PlotlyChart.vue";
import StatsText from "@/components/StatsText.vue";

export default {
  name: "Stats",
  components: { PlotlyChart, StatsText }
};
</script>
