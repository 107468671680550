<template>
  <section class="section has-text-centered statText">
    <div class="block">
    <h2 class="is-size-4 subtitle">statistics</h2>
      <p>
        We currently index {{ statText.number_teas }} different teas.<br />
        Where the oldest tea(s) with an age of
        {{ statText.oldest_tea_age }} years currently are:
      </p>
      <p v-for="tea in statText.oldest_teas" :value="tea" :key="tea.id">
        <a :href="tea.url">{{ tea.title }}</a>
      </p>
      <p>
        The most expensive tea with
        {{ statText.max_avg_price_per_gram.avg_price_per_gram }}$ per gram
        currently is
        <a :href="statText.max_avg_price_per_gram.url">
          {{ statText.max_avg_price_per_gram.title }}
        </a>
      </p>
    </div>
  </section>
</template>

<script>
import TeaScoutApi from "@/api/TeaScoutApi.js";

export default {
  name: "StatsText",
  components: {},
  data() {
    return { statText: null };
  },
  methods: {
    getStatText() {
      TeaScoutApi.getStatText().then(data => {
        this.statText = data;
      });
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.getStatText();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
