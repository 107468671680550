<template>
  <section class="graphs">
    <div>
      <div class="block">
        <h2 class="is-size-4 has-text-centered">
          number of products per shop
        </h2>
        <Plotly
          :data="shopTeaCountData"
          :layout="graphLayout"
          :display-mode-bar="false"
        ></Plotly>
      </div>
      <div class="block">
        <h2 class="is-size-4 has-text-centered">
          number of products per category
        </h2>
        <Plotly
          :data="teasPerCategoryData"
          :layout="graphLayout"
          :display-mode-bar="false"
        ></Plotly>
      </div>
      <div class="block">
        <h2 class="is-size-4 has-text-centered">
          average price per year ($/gr) of {{ categoryName }} tea
        </h2>
        <div class="columns">
          <div class="column is-1"></div>
          <div class="column  is-one-fifth">
            change the category to:
          </div>
          <div class="column">
            <CategorySelect v-bind:title-in-value="false"></CategorySelect>
          </div>
        </div>
        <Plotly
          :data="avgPriceYear"
          :layout="graphLayout"
          :display-mode-bar="false"
        ></Plotly>
      </div>
    </div>
  </section>
</template>

<script>
import { Plotly } from "vue-plotly";
import TeaScoutApi from "@/api/TeaScoutApi.js";
import CategorySelect from "@/components/CategorySelect.vue";

export default {
  name: "PlotlyChart",
  unsubscribe: null,
  components: {
    Plotly,
    CategorySelect
  },
  data() {
    return {
      shopTeaCountData: [
        {
          x: [],
          y: [],
          type: "bar",
          marker: { color: "#2d5e46" }
        }
      ],
      graphLayout: {
        autosize: true,
        automargin: true,
        margin: {
          t: 30
        }
      },
      teasPerCategoryData: [
        {
          x: [],
          y: [],
          type: "bar",
          marker: { color: "#2d5e46" }
        }
      ],
      avgPriceYear: [
        {
          x: [],
          y: [],
          type: "bar",
          marker: { color: "#2d5e46" }
        }
      ]
    };
  },
  methods: {
    renderAvgPriceGraph() {
      this.avgPriceYear[0].x = [];
      this.avgPriceYear[0].y = [];
      TeaScoutApi.shopPriceYearType(this.$store.state.selectedCategory.id).then(
        data =>
          data.result.forEach(year => {
            this.avgPriceYear[0].x.push(year.year);
            this.avgPriceYear[0].y.push(year.avgprice);
          })
      );
    }
  },
  computed: {
    categoryName() {
      return this.$store.state.selectedCategory
        ? this.$store.state.selectedCategory.display_title
        : "";
    }
  },
  mounted() {
    this.$nextTick(function() {
      TeaScoutApi.teaShopData().then(data =>
        data.result.forEach(shop => {
          this.shopTeaCountData[0].x.push(shop.shop_name);
          this.shopTeaCountData[0].y.push(shop.product_count);
        })
      );

      TeaScoutApi.teasPerCategory().then(data =>
        data.result.forEach(category => {
          this.teasPerCategoryData[0].x.push(category.product_category);
          this.teasPerCategoryData[0].y.push(category.category_count);
        })
      );

      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type == "setSelectedCategory") {
          this.renderAvgPriceGraph();
        }
      });
      this.renderAvgPriceGraph();
    });
  },
  unmounted() {
    this.unsubscribe();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
